<template>
  <div class="outer-wrapper">
    <div class="top-content">
      <div>
        <p class="des-title">概况</p>
        <p class="des-main">
          {{ description }}
        </p>
      </div>
      <div style="width: 500px; height: 500px; cursor: pointer; padding: 0">
        <img
          :src="imgUrl"
          alt=""
          style="width: 500px; height: 500px; cursor: pointer"
          @click="linkTo(imgUrl)"
        />
      </div>
    </div>

    <!--<table>
    <tr>
        <th>
            线路
        </th>
        <th>
            运营公司
        </th>
        <th>
            运营里程(Km)
        </th>
        <th>
            车站数量
        </th>
        <th>
            运营年限（截至2019年）
        </th>
        <th>
            车型
        </th>
    </tr>
    <tr v-for="(ele,index) in tdata" :key="'tr'+index">
        <td>
           {{ele.lineName}}
        </td>
        <td>
            {{ele.opCompany}}
        </td>
        <td>
            {{ele.opMile}}
        </td>
        <td>   
            {{ele.stationAmount}}
        </td>
        <td>
            {{ele.opYears}}
        </td>
        <td>
            {{ele.trainStyle}}
        </td>
    </tr>
</table>-->
    <el-tabs type="border-card" id="pane-anchor">
      <el-tab-pane>
        <span slot="label" @click="goAnchor('#pane-anchor')">
          <i class="el-icon-date"></i> 线网组成
        </span>
        <line-list></line-list>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label" @click="goAnchor('#pane-anchor')">
          <Icon type="ios-nuclear-outline" /> 风险分析
        </span>
        <div class="total-chart-wrapper">
          <div class="more_to">
            <router-link :to="{ name: 'risk_l' }"
              >点击查看更多风险点数据&nbsp;>></router-link
            >
          </div>
          <div class="single-chart-wrapper">
            <degree-level
              :choosedOptions="choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></degree-level>
          </div>
          <div class="single-chart-wrapper">
            <correct-stat
              :choosedOptions="choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></correct-stat>
          </div>
          <div class="single-chart-wrapper">
            <major-system
              :choosedOptions="choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></major-system>
          </div>
          <div class="single-chart-wrapper">
            <risk-sort
              :choosedOptions="choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></risk-sort>
          </div>
          <div class="single-chart-wrapper">
            <correct-demand
              :choosedOptions="choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></correct-demand>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import degreeLevel from "@/components/RiskResult/Risk/Charts/DegreeLevel.vue";
import correctStat from "@/components/RiskResult/Risk/Charts/CorrectStat.vue";
import riskSort from "@/components/RiskResult/Risk/Charts/RiskSort.vue";
import majorSystem from "@/components/RiskResult/Risk/Charts/MajorSystem.vue";
import correctDemand from "@/components/RiskResult/Risk/Charts/CorrectDemand.vue";
//let userToken = localStorage.getItem("current_user_token");
import lineList from "./LineList.vue";
export default {
  name: "netDescription",
  components: {
    lineList,
    degreeLevel,
    correctStat,
    riskSort,
    majorSystem,
    correctDemand,
  },
  data() {
    return {
      imgUrl: process.env.BASE_URL + "img/成都市.jpg",
      description:
        '成都地铁（Chengdu Metro），是指服务于中国四川省成都市的城市轨道交通。其首条线路成都地铁1号线于2010年9月27日正式开通，也使成都成为了中国大陆第十个拥有城市轨道交通的城市。据2021年12月，成都轨道交通共开通13条线路，线路总长557.366千米，其中仅蓉2号线采用有轨电车系统，其它线路采用地铁系统，共计331座车站投入运营（换乘站不重复计算），46座换乘站。',
      tdata: [],
      choosedOptions: {
        selectedList: [
          {
            key_name: "line_code",
            value: "net",
            text: "线网",
          },
        ],
        keyword: "",
      },
      updateCount: 1,
      echartSize: { height: "450px", width: "550px" },
    };
  },
  props: {},
  created() {},

  methods: {
    linkTo(value) {
      window.open(value, "_blank");
    },
    goAnchor(selector) {
      this.$el.querySelector(selector).scrollIntoView();
    },
  },
};
</script>
<style scoped>
.outer-wrapper {
  width: 100%;
  margin: 0 auto;
  font-size: 14px;
}
.top-content {
  display: flex;
  margin-bottom: 20px;
}

.top-content img {
  border: 1px solid #333;
}
.top-content div {
  padding: 50px 50px 50px 5px;
}
p {
  text-indent: 28px;
  line-height: 40px;
  text-align: justify;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

td,
th {
  height: 28px;
  border: 1px solid #666;
  text-align: center;
  padding: 0 10px;
}

.des-main {
  font-size: 18px;
  margin-top: 20px;
  text-indent: 36px;
  text-align: justify;
}

.des-title {
  font-size: 22px;
  font-weight: 500;
  text-indent: 0px;
  border-bottom: 1px solid rgb(44, 14, 14);
}

.single-chart-wrapper {
  display: inline-block;
  margin: 5px;
}

.total-chart-wrapper {
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}

.more_to {
  text-align: right;
  font-size: 14px;
  padding: 5px 8px 5px 0;
}
</style>